@import 'designSystem/colors.scss';

.dashboard-layout {
	display: flex;
	flex-direction: column;
	margin: 0;

	.top-bar {
		background-color: white;
		height: 80px;
		padding: 20px 35px;
		box-sizing: border-box;
		display: flex;
		align-items: center;

		.burger-menu-icon {
			display: none;
			position: absolute;
			top: 20px;
			left: 20px;

			padding: 10px;
			border-radius: 50px;
			width: 20px;
			height: 20px;
			justify-content: center;
			align-items: center;
			background-color: $primary20;
			color: $primary;
		}

		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			height: 80px;

			img {
				width: 110px;
			}
		}

		.logged-user-info {
			margin-left: auto;
			width: 230px;
			position: relative;
			flex-shrink: 0;

			.logged-user-info__content {
				color: $text-body;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				&:hover {
					cursor: pointer;
				}

				img {
					width: 45px;
					height: 45px;
					border: 2px solid #dadada;
					padding: 1px;
					border-radius: 100%;
				}

				span {
					margin: 0 15px;
					font-weight: 500;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					color: $text-body;
				}
			}

			.logged-user-info__options {
				position: absolute;
				width: 200px;
				background-color: white;
				top: 65px;
				right: 0;
				box-shadow: 0px 0px 8px 0px rgba(216, 216, 216, 0.75);

				.option {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					height: 30px;
					padding: 10px 20px;
					color: $text-body;

					&:hover {
						cursor: pointer;
						background-color: #f7f7f7;
					}

					span {
						margin-left: 10px;
						font-weight: 500;
					}
				}
			}
		}
	}

	.burger-menu-wrapper {
		display: none;
		position: absolute;
		width: 400px;

		.menu-header {
			display: flex !important;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			padding: 30px 10px;
			color: $primary;

			img {
				width: 90px;
			}
		}

		.sidebar-item {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			height: 45px;
			color: $text-body;
			text-decoration: none;
			font-weight: 600;
			transition: background-color 0.1s, color 0.1s;
			width: 200px;
			margin: 0 auto 20px auto;
			padding: 0 20px;
			box-sizing: border-box;
			border-radius: 9px;

			.sidebar-icon {
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 15px;
				font-size: 20px;
			}

			&.active,
			&:hover {
				background-color: $information;
				color: $primary;
				cursor: pointer;
				transition: background-color 0.1s, color 0.1s;
			}
		}

		.menu-item {
			color: $light-background;
			background-color: $primary;
			height: 48px;
			margin-bottom: 8px;
		}

		/* Color/shape of burger icon bars */
		.bm-burger-bars {
			background: $primary;
		}

		/* Color/shape of burger icon bars on hover*/
		.bm-burger-bars-hover {
			background: $light-background;
		}

		/*
			Sidebar wrapper styles
			Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
			*/
		.bm-menu-wrap {
			position: fixed;
			height: 100%;
			overflow-y: hidden;
		}

		/* General sidebar styles */
		.bm-menu {
			background: $light-background;
			overflow: hidden !important;
		}

		/* Morph shape necessary with bubble or elastic */
		.bm-morph-shape {
			fill: #373a47;
		}

		/* Wrapper for item list */
		.bm-item-list {
			color: #b8b7ad;
		}

		/* Individual item */
		.bm-item {
			display: inline-block;
		}

		/* Styling of overlay */
		.bm-overlay {
			background: rgba(0, 0, 0, 0.3);
		}
	}

	.content {
		display: flex;
		flex-direction: row;
		background: $background;
		flex: 1;

		.sidebar {
			width: 220px;
			background-color: white;
			display: flex;
			flex-direction: column;
			padding: 20px 0px;

			.sidebar-item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				height: 45px;
				margin-bottom: 20px;
				color: $text-body;
				text-decoration: none;
				font-weight: 600;
				transition: background-color 0.1s, color 0.1s;
				width: 200px;
				margin: 0 auto 20px auto;
				padding: 0 20px;
				box-sizing: border-box;
				border-radius: 9px;

				.sidebar-icon {
					width: 20px;
					height: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 15px;
					font-size: 20px;
				}

				&.active,
				&:hover {
					background-color: $information;
					color: $primary;
					cursor: pointer;
					transition: background-color 0.1s, color 0.1s;
				}
			}
		}

		.content-children {
			overflow-y: auto;
			flex: 1;
			height: 100%;
		}
	}
}

@media only screen and (max-width: 767px) {
	.dashboard-layout {
		.top-bar {
			display: flex;
			justify-content: center;
			align-items: center;
			.logged-user-info {
				display: none;
			}

			.burger-menu-icon {
				display: flex;
			}
		}

		.burger-menu-wrapper {
			display: block;
			width: 250px !important;

			.sidebar-item {
				margin-right: 10px;
				margin-left: 10px;
				width: 250px;
				display: flex !important;
				align-items: center;

				&:hover {
					background: none;
				}
			}

			.bm-menu-wrap {
				width: unset !important;
			}
		}

		.content {
			background-color: $light-background;
			.sidebar {
				display: none;
			}

			// .content-children {
			// 	overflow: initial;
			// }
		}
	}
}
