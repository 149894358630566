@import 'designSystem/colors.scss';

.button-component {
	padding: 10px 25px;
	color: $text-light;
	border: none;
	width: 100%;
	text-decoration: none;
	border-radius: 10px;
	cursor: pointer;
	font-size: 15px;
	margin-bottom: 10px;
	font-family: 'Montserrat';
	font-weight: 500;
	background-color: $primary;

	&.medium {
		width: 140px;
		padding: 6px 15px;
		font-size: 13px;
		margin-bottom: 10px;
	}

	&.small {
		width: 85px;
		padding: 4px 10px;
		font-size: 13px;
		margin-bottom: 10px;
	}

	.button-component-loading {
		flex: 1;
		height: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.button-content {
		display: flex;
		align-items: center;
		justify-content: center;

		& > svg {
			margin-right: 5px;
		}
	}

	&.secondary {
		border: 1px solid $text-body;
		color: $text-body;
		background-color: $text-light;

		&:hover {
			border-color: $primary;
			color: $primary;
			background-color: $text-light;
		}

		&.disabled {
			border: 1px solid $neutral20;
			color: $neutral20;
			background-color: $text-light;
		}
	}

	&.onlyText {
		background: none;
		color: $primary;

		&:hover {
			color: $primary-dark;
			background: none;
		}

		&:focus {
			box-shadow: none;
			background-color: $primary-light40;
		}

		&:disabled {
			background: none;
		}
	}

	&.cancel {
		background-color: $text-disabled;
	}

	&:focus {
		/*box-shadow: 0 0 0 6px #ff7d7d50;*/
	}

	&:hover {
		background-color: $background-dark;
		color: $text-light;
	}

	&.disabled {
		background-color: $neutral05;
		color: $neutral20;
		border: none;
		cursor: default;
	}

	&.round {
		border-radius: 32px;
	}

	&.light {
		background-color: unset;
		color: $text-label;
	}

	&.no-actions {
		pointer-events: none;
	}

	&.danger {
		background-color: $error-dark;
	}

	&.icon-button {
		width: 40px;
		height: 40px;
		border-radius: 50%;

		padding: 4px;

		display: flex;
		justify-content: center;
		align-items: center;
	}
}
