@import 'designSystem/colors.scss';

.modal__wrapper {
	box-sizing: border-box;
	background-color: $light-background;
	min-width: 600px;
	border-radius: 24px;

	header {
		box-sizing: border-box;

		padding: 15px 20px;
		padding-bottom: 25px;
		border-bottom: 1px solid $neutral10;

		display: flex;
		justify-content: space-between;
		align-items: center;

		font-size: 18px;
		font-weight: 500;

		svg {
			cursor: pointer;
		}
	}

	.modal__content {
		padding: 15px 20px;
		box-sizing: border-box;
	}

	footer {
		box-sizing: border-box;
		padding: 15px 20px;
		width: 100%;
		display: flex;
		justify-content: flex-end;

		.modal__buttons-container {
			width: min-content;
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 20px;

			.confirm-button {
				// background-color: $gray;
				// color: $text-body;

				&:hover {
					filter: brightness(0.9);
				}
			}
		}
	}
}
