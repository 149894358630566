@import './src/designSystem/colors.scss';

.login-component {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	.app-logo {
		max-width: 60px;
		margin-bottom: 30px;
	}

	form {
		width: 100%;

		div.input-wrapper:first-of-type {
			margin-bottom: 20px;
		}

		.forgot-password {
			display: block;
			text-decoration: underline;
			color: $primary;
			width: 100%;
			margin-bottom: 30px;
			margin-top: 10px;
			text-align: right;
			font-size: 12px;
		}
	}
}

.kit-images {
	position: absolute;
	bottom: 20px;

	display: flex;
	gap: 48px;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
