@import 'designSystem/colors.scss';

.calendar-wrapper {
	padding: 40px;
	display: flex;
	flex-direction: column;
	flex: 1;

	&__title {
		padding-right: 20px;
		color: $text-heading;
		font-weight: 500;
		font-size: 22x;
		margin-top: 0;
	}

	.calendar__butons-wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 15px;
	}

	.calendar__buttons-left {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;
	}

	.calendar__buttons-right {
		width: min-content;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 10px;

		.calendar__button-right {
			width: min-content;
			padding: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			img {
				padding-right: 7px;
			}
		}
	}

	.card-component {
		img {
			max-width: 150px;
			margin-bottom: 10px;
		}
	}

	.calendar__info-icon {
		position: absolute;

		bottom: 20px;
		right: 20px;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;
		border: 15px solid $secondary;

		z-index: 998;

		svg {
			background-color: $secondary;
			color: $light-background;
			margin: -4px;
		}
	}

	.calendar__info-menu {
		background-color: #ff0;
		max-width: 400px;
		position: absolute;

		bottom: 30px;
		right: 30px;
		z-index: 999;
	}
}

.modal-calendar__content {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'employee employee'
		'startDate endDate'
		'status status';
	grid-gap: 20px;

	.employee {
		grid-area: employee;
	}

	.startDate {
		grid-area: startDate;
	}

	.endDate {
		grid-area: endDate;
	}

	.status {
		grid-area: status;
	}
}

.bottonInfo__hilighted {
	color: $primary;
	text-decoration: underline;
}

.warning-message {
	font-size: 13px;
	font-weight: 500;
	margin-top: 5px;
	color: #ff8c40;
	display: block;
}

@media only screen and (max-width: 767px) {
	.calendar-wrapper {
		padding: 0;

		&__title {
			padding: 0 20px;
		}

		.calendar__butons-wrapper {
			padding: 0 20px;
			overflow-x: auto;

			&::-webkit-scrollbar {
				height: 0px;
			}
		}

		.calendar__buttons-left {
			grid-template-columns: repeat(3, min-content);
			margin-right: 30px;

			.button-component {
				padding: 10px;
			}
		}

		.fullcalendar-wrapper {
			margin: 20px;
			height: 1108px;
			box-shadow: 0px 2px 4px rgb(192, 192, 192);
			overflow: hidden;

			.caption-wrapper {
				display: flex;
				flex-direction: column;
				grid-gap: 5px;
				top: 15px;
				right: 10px;

				.option {
					font-size: 10px;

					.type {
						width: 10px;
						height: 10px;
					}
				}
			}

			.fc-header-toolbar {
				margin-bottom: 10px;
			}
		}

		.fc-col-header {
			width: 100% !important;
		}

		.fc-daygrid-body {
			width: 100% !important;
		}
	}

	.modal-calendar__content {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.modal__wrapper {
		min-width: unset;
	}
}
