@import 'designSystem/colors.scss';

.personal-wrapper {
	padding: 40px;
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 15px;
	}

	&__title {
		color: $text-heading;
		font-weight: 500;
		font-size: 22px;
		margin-top: 0;
	}

	&__add-button {
		width: auto;
	}

	.card-component {
		padding: 0;
		img {
			max-width: 150px;
			margin-bottom: 10px;
		}

		h1 {
			font-size: 25px;
		}
	}
}

.personal-table {
	width: 100%;

	&__action {
		padding: 8px;
		border-radius: 5px;
		margin-left: 10px;
		display: inline-flex;

		cursor: pointer;
		&--edit {
			background-color: $primary-light-blue;
			color: $primary;
		}
		&--delete {
			background-color: $error-light;
			color: $primary-dark;
		}
		&:hover {
			filter: brightness(0.9);
		}
	}
}

.personal-form {
	&__input {
		margin-bottom: 15px;
	}
}

.MuiDataGrid-main {
	&.css-204u17-MuiDataGrid-main,
	&.css-opb0c2 {
		div:nth-child(3),
		div:nth-child(4) {
			color: #ffffff00 !important; //transparent

			div {
				color: #000;
			}
		}
	}
}
