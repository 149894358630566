@import 'designSystem/colors.scss';

.fullcalendar-wrapper {
	display: flex;
	height: 1100px;
	flex-direction: column;
	overflow: none;
	background-color: $light-background;
	border-radius: 8px;
	box-shadow: 4px 4px 6px rgb(192, 192, 192);
	position: relative;

	.fc {
		.fc-view-harness {
			height: min-content !important;
		}

		.fc-scroller-liquid-absolute {
			overflow: hidden !important;
		}

		.fc-toolbar-chunk {
			display: flex;
			align-items: center;
		}

		.fc-daygrid-day {
			height: 170px;
			&.fc-day {
				background-color: $light-background;
				&.fc-day-disabled {
					background-color: rgb(235, 235, 235);
				}
				&.fc-day-tue {
					&.fc-day-past,
					&.fc-day-other {
						height: 170px;
					}
				}
			}
		}

		.fc-event-title-container {
			text-align: center;
		}

		.fc-button-primary {
			background-color: $light-background;
			border: 2px solid $primary;
			color: $primary;
			width: 28px;
			height: 28px;
			display: flex;
			justify-content: center;
			align-items: center;

			&:disabled {
				opacity: 0.3;

				&:hover {
					background-color: $light-background;
					border: 2px solid $primary;
					color: $primary;
				}
			}

			&:focus:active:hover,
			&:hover {
				background-color: $primary;
				border: 2px solid $primary;
				color: $light-background;
				box-shadow: none;
				box-shadow: 0 0 0 #fff;
			}
		}

		.fc-button {
			&:focus,
			&:hover,
			&:active {
				box-shadow: none;
			}
		}

		.fc-header-toolbar {
			padding: 15px;
			margin: 0;
		}
		.fc-toolbar-title {
			width: min-content;
			font-size: 18px;
			// min-width: 190px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.fc-scrollgrid {
			overflow: hidden;
			height: 1050px;
		}

		.fc-daygrid-block-event .fc-event-time,
		.fc-daygrid-block-event .fc-event-title {
			color: $text-heading;
		}
	}

	.fc-theme-standard {
		th {
			border-top: none;
			border-right: none;
			border-left: none;
		}

		.fc-scrollgrid {
			border: none;
		}
	}

	.caption-wrapper {
		position: absolute;
		right: 15px;
		top: 17px;
		display: grid;
		grid-template-areas: 'here there other';
		grid-gap: 25px;
		font-size: 14px;
		cursor: default;

		.option {
			display: flex;
			align-items: center;

			.type {
				width: 20px;
				height: 20px;
				border-radius: 6px;
				background-color: $border;
				margin-right: 5px;
			}

			.vacaciones {
				background-color: $success;
			}

			.guardia {
				background-color: #76961d;
			}
		}
	}
}
