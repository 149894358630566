@import 'designSystem/colors.scss';

.action-modal__wrapper {
	box-sizing: border-box;
	background-color: $light-background;
	min-width: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-radius: 10px;

	h2 {
		margin: 0;
		margin-top: 10px;
		color: $text-heading;
	}

	p {
		max-width: 300px;
		text-align: center;
		color: $text-body;
		font-weight: 400;
	}

	.icon {
		padding: 15px;
		display: flex;
		justify-content: center;
		align-items: center;

		color: $error-dark;
		background-color: $error-light;
		border-radius: 100px;
	}

	footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		.action-modal__buttons-container {
			display: flex;
			align-items: center;

			button {
				margin: 0;
			}

			.confirm-button {
				margin: 0;
				background-color: $error-dark;
				margin-left: 10px;

				&:hover {
					filter: brightness(0.9);
					color: $light-background;
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.action-modal__wrapper {
		min-width: unset;
	}
}
