@import 'designSystem/colors.scss';

.custom-page-wrapper {
	padding: 40px;
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;

	.card-component {
		svg {
			font-size: 70px;
			color: $primary;
			margin-bottom: 20px;
		}

		ul {
			max-width: 800px;
		}
	}
}
