@import 'designSystem/colors.scss';

.auth-layout {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	.background-image {
		flex: 1;
		width: 100%;
		height: 100%;
		position: relative;

		.background-image__overlay {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}
	}

	.auth-layout__left {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.auth-layout__card {
			padding: 50px 0;
			box-sizing: border-box;
			width: 400px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			h1 {
				font-size: 35px;
				font-weight: 700;
				text-align: center;
				margin: 0;
				margin-bottom: 10px;
				color: $text-heading;
			}
			h2 {
				font-size: 24px;
				font-weight: 400;
				text-align: center;
				margin: 0;
				margin-bottom: 20px;
				color: $text-body;
			}
			h3 {
				font-weight: 700;
				font-size: 28px;
				color: $text-heading;
			}

			.separator {
				display: block;
				margin-bottom: 10px;
				text-align: center;
				font-size: 14px;
				line-height: 17px;
				color: $text-body;
				font-weight: 500;
			}

			.error-message-global {
				color: $error;
				text-align: center;
				margin: 20px;
			}
		}
	}
}

// @media only screen and (max-width: 780px) {
// 	.auth-layout {
// 		.auth-layout__left {
// 			.auth-layout__card {
// 				width: 100%;
// 			}
// 		}

// 		.background-image {
// 			display: none;
// 		}
// 	}
// }

@media only screen and (max-width: 900px) {
	.auth-layout {
		.auth-layout__left {
			margin-top: -200px;

			.auth-layout__card {
				padding-right: 5px;
				width: 90%;
			}
		}

		.background-image {
			display: none;
		}
	}
}
