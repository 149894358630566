@import 'designSystem/colors.scss';

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
	top: unset !important;
	left: unset !important;
	max-width: 400px !important;

	bottom: 60px !important;
	right: 60px !important;

	&body {
		padding-right: 0;
	}
}

.bottomInformation {
	&__wrapper {
		position: absolute;

		bottom: 22px;
		right: 22px;

		&.bottomInformation__info-menu {
			max-width: 400px;
			position: absolute;

			bottom: 30px;
			right: 30px;
			z-index: 999;

			padding: 10px;
		}
	}

	&__info-icon {
		position: relative;

		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0;
		padding: 0;
		border: 12px solid $secondary;
		background-color: $secondary;
		color: $light-background;

		z-index: 998;

		svg {
			margin: -4px;
		}
	}
}

.bottomInformation__content {
	padding: 10px 15px;
	box-sizing: border-box;

	h4 {
		margin: 0;
		display: flex;
		align-items: center;
		padding-bottom: 10px;
		color: $text-heading;

		svg {
			margin-right: 5px;
		}
	}

	p {
		margin: 0;
		line-height: 1.5;
	}
}
