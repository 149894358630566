@import './src/designSystem/colors.scss';

.new-password-component {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	.section-title{
		align-self: flex-start;
		display: flex;
    	align-items: center;
		margin: 15px 0;

		svg{
			cursor: pointer;
			margin-right: 10px;
		}
	}

	.section-subtitle{
		font-weight: 400;
		font-size: 16px;
		color: $text-heading;
	}

	form {
		width: 100%;

		div.input-wrapper {
			margin-bottom: 20px;
		}
	}
}
