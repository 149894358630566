@import 'https://fonts.googleapis.com/css?family=Ubuntu:wght@300,400,600,700';
@import 'designSystem/colors.scss';

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-Medium.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-SemiBold.ttf');
	font-weight: 600;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./assets/fonts/Montserrat-Bold.ttf');
	font-weight: 700;
}

body,
* {
	font-family: 'Ubuntu';
}

html,
body,
#root,
.App,
.App > div {
	overflow: auto !important;
	margin: 0;
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $secondary;
}

h1 {
	font-family: 'Montserrat';
	font-size: 22px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 600;
}

h2 {
	font-family: 'Ubuntu';
	font-size: 17px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 600;
}

h3 {
	font-family: 'Ubuntu';
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 600;
}

.subtitle1 {
	font-family: 'Ubuntu';
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 500;
}

p {
	&.body1 {
		font-family: 'Ubuntu';
		font-size: 16px;
		letter-spacing: 0px;
		line-height: 193.9%;
		font-weight: 400;
	}
	&.body2 {
		font-family: 'Ubuntu';
		font-size: 14px;
		letter-spacing: 0px;
		line-height: 171.4%;
		font-weight: 500;
	}
}

.button {
	font-family: 'Ubuntu';
	font-size: 14px;
	letter-spacing: 0.25px;
	line-height: 144.4%;
	font-weight: 600;
}

.text-button {
	font-family: 'Ubuntu';
	font-size: 13px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 600;
}

.outlined {
	text-decoration: underline;
}

.input-text,
.label-text,
.helper-text {
	font-family: 'Ubuntu';
	font-size: 14px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 600;
}

.tag {
	font-family: 'Ubuntu';
	font-size: 12px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 500;

	// &.1{
	//     color: $tag1;
	// }

	// &.2{
	//     color: $tag2;
	// }

	// &.3{
	//     color: $primary;
	// }
}

.label {
	font-family: 'Ubuntu';
	font-size: 11px;
	letter-spacing: 0px;
	line-height: 144.4%;
	font-weight: 400;
}

.soft-shadow {
	box-shadow: 0 13px 17px #bfbfbf15;
}

.medium-shadow {
	box-shadow: 0 13px 17px #bfbfbf25;
}

.strong-shadow {
	box-shadow: 0 16px 23px #bfbfbf35;
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgb(228, 228, 228);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(192, 192, 192);
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(170, 170, 170);
}

:focus-visible {
	outline: -webkit-focus-ring-color auto 0px;
}
