@import 'designSystem/colors.scss';

.card-component {
	flex: 1;
	box-sizing: border-box;
	padding: 30px;
	width: 100%;
	height: 100%;
	background-color: $light-background;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	border-radius: 5px;

	&.center-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	p {
		color: $text-label;
		font-weight: 500;
	}

	ul {
		li {
			font-weight: 500;
			color: $text-label;
			line-height: 30px;
		}
	}
}
