@import 'designSystem/colors.scss';

.upload-button {
	width: auto;
	border-color: #0087d1;
	color: white;
	background-color: #0087d1;
	border-radius: 9px;
	margin: 0;
}

.file-container {
	/*temporary width*/
	width: 500px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border: 1px solid $gray;
	border-radius: 9px;
	padding: 10px;

	&__text {
	}
	&__cancel {
		color: $border;
		display: flex;
		cursor: pointer;
	}
}

.upload-container {
	display: flex;
	gap: 12px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.upload-icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: $primary;
	border-radius: 50%;
	background-color: $primary-light-blue;
	width: 32px;
	height: 32px;
}

@media only screen and (max-width: 767px) {
	.upload-container {
		padding: 5px 10px;
		border: 1px solid $border;
		border-radius: 9px;

		.placeholder {
			color: $border;
		}

		.upload-button {
			padding: 10px;
			.button-content > svg {
				margin: 0;
			}
		}
	}

	.file-container {
		width: unset !important;
	}
}

@media only screen and (max-width: 930px) {
	.file-container {
		width: 100%;
	}
}
