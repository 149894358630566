@import './src/designSystem/colors.scss';

.register-component {
	display: flex;
	flex-direction: column;
	width: 100%;

	form {
		width: 100%;

		div.input-wrapper {
			margin-bottom: 20px;
		}

		button:first-of-type {
			margin-top: 20px;
		}
	}
}
