@import 'designSystem/colors.scss';

.input-wrapper {
	display: flex;
	flex-direction: column;

	&.error {
		label {
			color: $error-dark;
		}

		input {
			border-color: $error-dark;
		}
	}

	label {
		margin-bottom: 5px;
		font-weight: 500;
		font-size: 16px;
		color: $text-label;
	}

	textarea{
		padding: 10px 10px !important;
		height: 70px !important;
	}

	input, textarea {
		width: 100%;
		height: 35px;
		box-sizing: border-box;
		border: 1px solid $border-color;
		border-radius: 5px;
		padding: 0 10px;

		&:focus {
			outline: 0;
			border-color: $primary;
		}

		&::placeholder {
			opacity: 0.7;
		}
	}

	.DatePicker-wrapper {
		.ranger-wrapper {
			//border: 1px solid $border;
			border-radius: 9px;
			padding: 13px 10px;
		}
	}

	.error-message {
		font-size: 13px;
		font-weight: 500;
		margin-top: 5px;
		color: $error-dark;
	}
}

@media only screen and (max-width: 767px) {
	.input-wrapper {
		width: 100%;
		input {
			height: unset;
			padding: 17px 10px;

			border-radius: 9px;
		}
	}
}
