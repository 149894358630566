@import 'designSystem/colors.scss';

.confirmation-modal__wrapper {
	box-sizing: border-box;
	background-color: $light-background;
	min-width: 600px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 100px;
	border-radius: 10px;

	h2 {
		margin: 0;
		margin-top: 10px;
		color: $text-heading;
	}

	p {
		max-width: 350px;
		text-align: center;
		color: $text-body;
		font-weight: 400;
	}

	.icon {
		padding: 15px;
		display: flex;
		justify-content: center;
		align-items: center;

		color: $light-background;
		background-color: $primary;
		border-radius: 100px;
	}

	.confirmation-modal__button-container {
		display: flex;
		justify-content: center;
		margin-top: 40px;

		svg {
			margin-left: 10px;
		}

		button {
			margin: 0;
			color: $primary;
		}

		.confirm-button {
			margin: 0;
			background-color: $error-dark;
			margin-left: 10px;

			&:hover {
				filter: brightness(0.9);
				color: $light-background;
			}
		}
	}
}
