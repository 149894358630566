@import 'designSystem/colors.scss';

.installations-wrapper {
	padding: 40px;
	display: flex;
	flex-direction: column;
	min-height: 100%;
	box-sizing: border-box;

	&__title {
		color: $text-heading;
		font-weight: 500;
		font-size: 22px;
		margin-top: 0;
	}

	&__head {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		padding-bottom: 20px;
	}

	&__search{
		margin-bottom: 10px;
	}

	&__button {
		width: auto;
		border-color: #0087d1;
		color: white;
		background-color: #0087d1;
		border-radius: 9px;
	}

	.card-component {
		padding: 0;
		img {
			max-width: 150px;
			margin-bottom: 10px;
		}

		h1 {
			font-size: 25px;
			// color: $primary;
		}
	}
}

.installations-selector {
	display: flex;
	flex-direction: row;
	gap: 10px;

	&__button {
		width: auto;
		font-weight: 400;
		font-size: 15px;

		&--active {
			border-color: $primary !important;
			color: white !important;
			background-color: $primary !important;
		}
	}
}

.installations-table {
	width: 100%;
	flex: 1;
}

.table-row-menu {
	&__opener {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		width: 26px;
		height: 26px;
		line-height: 26px;
		&:hover {
			background: $background;
		}
	}

	&__options {
		padding: 7px;
	}

	&__icon {
		margin-right: 10px;
	}
}

.details-card {
	justify-content: flex-start !important;

	&__header {
		display: flex;
		align-items: center;
		gap: 33px;

		padding: 33px;
		width: calc(100% - 66px);
		border-bottom: 1px solid rgba(9, 30, 66, 0.08);
		box-shadow: 0px 2px 4px rgba(9, 30, 66, 0.06);
	}
	&__add-button {
		background-color: #e3f5ff;
		color: $primary;
		width: auto;
		margin-bottom: 0;
		padding: 8px 10px;
	}
	&__title {
		margin: 0;
		font-weight: 500;
		font-size: 18px;
		color: $text-heading;
	}
	&__content {
		padding: 30px;
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	&__back-button {
		cursor: pointer;
	}
}

.info-card {
	border-color: $information;
	background-color: $information24;
	&__header {
		padding: 16px;
		width: calc(100% - 32px);
		border-bottom: 1px solid #ebedf0;
		box-shadow: 0px 2px 4px rgba(9, 30, 66, 0.06);
	}

	&__content {
		padding: 20px;
		display: flex;
		flex-direction: row;
		gap: 64px;
		overflow: auto hidden;
	}
}

.assign-card {
	&__header {
		padding: 16px;
		width: calc(100% - 32px);
		border-bottom: 1px solid #ebedf0;
		box-shadow: 0px 2px 4px rgba(9, 30, 66, 0.06);
	}
	&__add-button {
		background-color: #e3f5ff;
		color: $primary;
		width: auto;
		margin-bottom: 0;
		padding: 8px 10px;
	}
	&__content {
		&--empty {
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			& > img {
				width: 70px;
			}
			& > h4 {
				text-align: center;
				font-weight: 500;
				font-size: 18px;
				color: $text-heading;
				margin: 5px 0;
			}
			& > p {
				text-align: center;
				font-weight: 400;
				font-size: 14px;
				color: $text-body;
				margin: 5px 0;
			}
		}
	}
}

.assign-info {
	&__content {
		padding: 20px;
		display: grid;
		grid-gap: 20px;
		grid-template-columns: 1fr 1fr;
		h4 {
			margin: 0;
		}

		&--1st-row {
			grid-template-areas:
				'title title'
				'dateStart dateEnd';
		}
		&--2nd-row {
			grid-template-areas: 'manager manager';
		}
		&--3rd-row {
			grid-template-areas:
				'title title'
				'personal vehicle';
		}
		&--4nd-row {
			grid-template-areas:
				'title title'
				'preDocuments preDocuments'
				'documents documents';
		}
	}
	&__button {
		width: fit-content;
		border-color: #0087d1;
		color: white;
		background-color: #0087d1;
		border-radius: 9px;
		margin: 0;
	}

	.save-button {
		padding: 10px 20px;
		padding-top: 0;
	}

	&__predocument-row {
		width: 500px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border: 1px solid $gray;
		border-radius: 9px;
		padding: 10px;
	}
	&__predocument-cancel {
		color: $border;
		display: flex;
		cursor: pointer;
	}
	&__document-row {
		display: flex;
		gap: 10px;
	}

	&__divider {
		border-top: 1px solid rgba(9, 30, 66, 0.08);
	}

	&__title {
		grid-area: title;
		display: flex;
		align-items: center;
		gap: 20px;
	}
	&__date-start {
		grid-area: dateStart;
	}
	&__date-end {
		grid-area: dateEnd;
	}
	&__manager {
		grid-area: manager;
	}
	&__personal {
		grid-area: personal;
	}
	&__vehicle {
		grid-area: vehicle;
	}
	&__preDocuments {
		grid-area: preDocuments;
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	&__documents {
		grid-area: documents;
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
}

.info-item {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 9px;

	&__title {
		font-weight: 500;
		font-size: 16px;
		color: $text-heading;
		margin: 0;
	}
	&__text {
		font-weight: 400;
		font-size: 14px;
		color: $text-body;
		margin: 0;
	}
}

.assign-manager-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'dateStart dateEnd'
		'comment comment';
	grid-gap: 20px;
}

.assign-comment{
	padding: 10px;
	background: rgba(242, 135, 36, 0.15);
	border-radius: 6px;

	span{
		display: block;
	}
	&__title{
		font-weight: 500;
		font-size: 16px;
	}

	&__text{
		font-weight: 400;
		font-size: 14px;
		margin-top: 8px;
	}
}

.assign-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-areas:
		'dateStart dateEnd'
		'manager manager'
		'personal personal'
		'vehicle vehicle'
		'documents documents'
		'comment comment';
	grid-gap: 20px;
	&__date-start {
		grid-area: dateStart;
	}
	&__date-end {
		grid-area: dateEnd;
	}
	&__manager {
		grid-area: manager;
	}
	&__comment {
		grid-area: comment;
	}
	&__personal {
		grid-area: personal;
	}
	&__vehicle {
		grid-area: vehicle;
	}
	&__documents {
		grid-area: documents;
	}
	&__label {
		margin-bottom: 5px;
		font-weight: 500;
		font-size: 16px;
		color: $text-label;
	}
	&__button {
		width: fit-content;
		border-color: #0087d1;
		color: white;
		background-color: #0087d1;
		border-radius: 9px;
		margin: 0;
	}
	&__documents {
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	&__document-row {
		display: flex;
		gap: 10px;
	}
}

.instalation-form {
	& > span {
		margin-bottom: 10px;
		display: block;
	}
}

.warning-message {
	font-size: 13px;
	font-weight: 500;
	margin-top: 5px;
	color: #ff8c40;
	display: block;
}

@media only screen and (max-width: 767px) {
	.installations-selector {
		padding-right: 30px;
		&__button {
			width: min-content;
			font-weight: 400;
			font-size: 15px;

			&.button-component {
				padding: 10px 15px;
				margin: 0;

				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&--active {
				border-color: $primary !important;
				color: white !important;
				background-color: $primary !important;
			}
		}
	}

	.installations-wrapper {
		&__head {
			overflow-x: auto;
			margin-bottom: 20px;
			padding: 0 20px;

			&::-webkit-scrollbar {
				height: 0px;
			}

			.button-create {
				.button-component {
					width: 150px;
					padding: 10px 15px;
					margin: 0;

					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.installations-wrapper {
		padding: 0;

		&__title {
			padding: 0 20px;
		}

		.center-content {
			padding: 0 20px 20px;
		}

		.card-component {
			box-shadow: none;

			.details-card {
				&__header {
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: none;
					border: none;
					position: relative;

					h2 {
						padding: 0 20px;
					}
				}

				&__header:has(h3) {
					justify-content: start;
					border-bottom: 1px solid #091e4220;
				}

				&__back-button {
					position: absolute;
					left: 30px;
					display: flex;
					align-items: center;

					background-color: $primary;
					color: $text-light;
					padding: 5px;
					border-radius: 100%;
				}

				&__content {
					padding: 0;
					margin: 30px;
					margin-top: 0;
					border-radius: 3px;
					box-shadow: 0 2px 3px $border;
				}
			}
		}

		.css-1e2bxag-MuiDataGrid-root {
			box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 10%);
		}
	}

	.info-card {
		&__header {
			border-bottom: 1px solid $border;
			text-align: start !important;
		}

		&__content {
			flex-direction: column;
			// padding: 0;
		}
	}

	.details-card {
		&__header {
			text-align: center;
		}
	}

	.assign-form {
		display: flex;
		flex-direction: column;

		&__manager,
		&__personal,
		&__vehicle {
			.css-ebr7n9-control {
				padding: 5px 0px;
			}
		}

		&__documents {
			gap: 0;
			grid-gap: 0;
		}
	}

	.assign-card {
		.assign-info {
			&__content {
				display: flex;
				flex-direction: column;
			}
		}
	}
}
