@import 'designSystem/colors.scss';

.react-modal__wrapper {
	z-index: 999;
}

@media only screen and (max-width: 767px) {
	.ReactModal__Content {
		width: 95%;
		max-width: unset !important;
		max-height: unset !important;
	}

	.react-modal {
		.modal-body {
			.modal__wrapper {
				max-height: max-content;
				overflow: 'auto';
				box-sizing: border-box;
			}
		}
	}
}

@media only screen and (max-height: 730px) {
	.ReactModal__Content {
		//width: 100% !important;
		overflow: auto !important;
		border-radius: 0px !important;
	}
}
