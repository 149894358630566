@import 'designSystem/colors.scss';

.vehicles-wrapper {
	padding: 40px;
	display: flex;
	flex-direction: column;
	height: 100%;
	box-sizing: border-box;

	&__title {
		padding-right: 20px;
		color: $text-heading;
		font-weight: 500;
		font-size: 22x;
		margin: 0;
	}

	&__title{
		color: $text-heading;
		font-weight: 500;
		font-size: 22px;
		margin: 0;
	}

	header {
		display: flex;
		align-items: center;
		width: min-content;
		white-space: nowrap;

		.header-button {
			margin: 0;
			padding: 10px;
			// cursor: pointer;
		}
	}

	.table___wrapper {
		margin-top: 20px;
		flex: 1;
		overflow: hidden;
		background-color: $light-background;
	}

	.vehicles-actions {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;

		.vehicles-actions__button-wrapper {
			padding: 8px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 5px;

			&.edit {
				background-color: $primary-light-blue;
				color: $primary;
			}

			&.remove {
				background-color: $error-light;
				color: $primary-dark;
				margin-left: 10px;
			}

			&:hover {
				filter: brightness(0.9);
			}
		}
	}
}

.form-input {
	margin-bottom: 15px;
}
