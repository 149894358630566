// Main Palette
$primary-light40: #fbfbfb40;
$primary-light: #fbfbfb;
$primary: #0087d1;
$primary20: #0087d120;
$primary-light-blue: #e3f5ff;
$primary-dark: #ab2828;

$secondary-light: #707479;
$secondary: #475569;
$secondary-dark: #1c2024;

$gray: #ccd3dd;
$border: #94a0b0;
$error-light: #ffe8e3;

//Neutral
$neutral00: #ffffff;
$neutral05: #f2f2f2;
$neutral10: #d6d6d6;
$neutral20: #828282;
$neutral40: #4f4f4f;
$neutral60: #262626;
$neutral80: #0d0d0d;

//Feedbacks
//Success
$success-light: #bbe4d8;
$success: #40c9a8;
$success-dark: #34ae89;

//Warning
$warning-light: #ffd9be;
$warning: #ffb47d;
$warning-dark: #ff8e3c;

//Information
$information-light: #d4edff;
$information: #eff6ff;
$information24: #eff6ff24;
$information-dark: #7fc9ff;

//Error
$error-light: #f8c7c7;
$error: #f28f8f;
$error-dark: #ff4040;

//Opacities
//Light
$opacity-light05: #ffffff05;
$opacity-light10: #ffffff10;
$opacity-light20: #ffffff20;
$opacity-light40: #ffffff40;
$opacity-light80: #ffffff80;
$opacity-light: #ffffff15;

//Dark
$opacity-dark05: #00000005;
$opacity-dark10: #00000010;
$opacity-dark20: #00000020;
$opacity-dark40: #00000040;
$opacity-dark80: #00000080;
$opacity-dark: #00000015;

//TODO missing gradients

//Tags
$tag1: #4d6194;
$tag2: #7fc1f0;

// Texts Palette
$text-heading: #1e293b;
$text-body: #475569;
$text-label: #475569;
$text-disabled: #b6b6b6;
$text-light: #fff;

$background: #f2f2f2;
$background-dark: #0176b7;
$light-background: #fff;

// Other
$border-color: #a9a9a9;
