@import 'designSystem/colors.scss';

.DatePicker-wrapper {
	width: 100%;

	label {
		margin-bottom: 5px;
		font-weight: 500;
		font-size: 15px;
		color: $text-heading;
		font-size: 14px;
	}

	.ranger-wrapper {
		width: 100%;
		background-color: $light-background;
		border: 1px solid #94A0B0;
		border-radius: 5px;
		padding: 15px 10px;
		height: auto !important;
		box-shadow: none;

		input {
			color: $text-heading;
			&::placeholder {
				color: $text-heading;
			}
		}
		svg {
			color: $text-heading;
		}
		.ant-picker {
			padding: 0;
		}

		.ant-picker-active-bar {
			display: none;
		}

		&--disabled{
			background-color: #EFF6FF;
			border: none;
		}
	}
}
